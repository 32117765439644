<template>
  <div v-cloak v-if="apiLoaded" v-show="show">
    <v-row class="top-progress">
      <v-col lg="4">
        <div class="green">
          <div>{{ dashboardData != null ? dashboardData.I : 0 }}<span class="text-caption">/{{ dashboardData != null ? dashboardData.I_max_value : 0 }}</span></div>
          <p>
            Индекс<br>клиентоцентричности
          </p>
        </div>
      </v-col>
      <v-col lg="4">
        <div class="blue">
          <div>{{ dashboardData != null ? dashboardData.Is : 0 }}<span class="text-caption">/{{ dashboardData != null ? dashboardData.Is_max_value : 0 }}</span></div>
          <p>
            Индекс
            клиентоцентричности
            с точки зрения
            соискателей
          </p>
        </div>
      </v-col>
      <v-col lg="4">
        <div class="yellow">
          <div>{{ dashboardData != null ? dashboardData.Ir : 0 }}<span class="text-caption">/{{ dashboardData != null ? dashboardData.Ir_max_value : 0 }}</span></div>
          <p>
            Индекс
            клиентоцентричности
            с точки зрения
            работодателей
          </p>
        </div>
      </v-col>
    </v-row>

    <div class="date-switch">
      <svg
          v-if="(formData !== null && formData.previousFormData !== null) ||
          ( dashboardData !== null && dashboardData.quarter !== null)"
          @click="previousQuarter()"
          style="cursor: pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
        <path d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z" fill="#0033A0"/>
      </svg>

      <p>{{checkQuarter() ? 'Текущий квартал' : currentQuarter.value + ' квартал ' + currentQuarter.year + ' года'}}</p>
      <svg
          v-if="!checkQuarter()"
          @click="nextQuarter()"
          style="cursor: pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
        <path d="M8.58984 16.58L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.58Z"
              fill="#0033A0"/>
      </svg>
    </div>

      <template v-if="viewMode !== 'REGION'">
      <h1>Показатели индекса клиентоцентричности
      <div class="download-menu">
        <v-dialog
            v-model="dashboardExportDialog"
            max-width="600px"
            persistent>
          <template v-slot:activator="{ on }">
            <div class="download-menu-icon" v-on="on">
              <svg
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                    fill="#0033A0"
                />
              </svg>
            </div>
          </template>

          <DashboardExportDialog
              @close="dashboardExportDialog=false; exportDialogKey++"
              :index-groups="dynamicIndexGroups"
              :refs="$refs"
              :key="exportDialogKey"/>
        </v-dialog>
      </div>
    </h1>

    <div class="list passport">
      <div class="list-item-wrap">
        <v-expansion-panels multiple :value="expandedPanels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="list-item">
                <a class="list-item-name">
                  <button class="toggleSwitch">
                    <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                    </svg>
                    <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                    </svg>
                  </button>
                  <span>1. Показатели дружелюбия и партнерства</span>
                </a>
                <div class="badge green"> {{ dashboardData.D_svod }}/{{ dashboardData.D_max_value }}</div>
                <div class="badge blue">{{ dashboardData.Ds_svod }}/{{ dashboardData.D_max_value }}</div>
                <div class="badge yellow">{{ dashboardData.Dr_svod }}/{{ dashboardData.D_max_value }}</div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels multiple :value="expandedPanels">
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>1. Уровень профессиональных компетенций сотрудников
                                  </span>
                          <div class="badge green"> {{dashboardData.D1 }}/{{dashboardData.D1_max_value }}</div>
                          <div class="badge blue">{{dashboardData.Ds1 }}/{{dashboardData.D1_max_value }}</div>
                          <div class="badge yellow">{{dashboardData.Dr1 }}/{{dashboardData.D1_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'friendship.professionalCompetences'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.friendship.professionalCompetences">
                          <section slot="pdf-content">
                            <v-container>
                            <v-row>
                        <v-col xl="2" class="d-flex flex-column">
                          <p class="mt-auto mb-0">Сотрудники ЦЗН обладают высоким уровнем профессиональных знаний для
                            оказания услуг и сервисов в сфере занятости.</p>
                        </v-col>
                        <v-col xl="5">
                          <div class="graph-wrap">
                            <div class="graph-top text-blue">
                              <div>{{ dashboardData.Ds1 }}<span>/{{ dashboardData.D1_max_value }}</span></div>
                              <div>Соискатели <span>{{getAnswersCount('Ds1')}}</span></div>
                            </div>
                            <GChart
                                type="ColumnChart"
                                :data="getDiagramData('Ds1')"
                                :options="columnChartOptions"
                            />
                          </div>
                        </v-col>
                        <v-col xl="5">
                          <div class="graph-wrap">
                            <div class="graph-top text-orange">
                              <div>{{ dashboardData.Dr1 }}<span>/{{ dashboardData.D1_max_value }}</span></div>
                              <div>Работодатели <span> {{getAnswersCount('Dr1') }}</span></div>
                            </div>
                            <GChart
                                type="ColumnChart"
                                :data="getDiagramData('Dr1')"
                                :options="columnChartOptions"
                            />
                          </div>
                        </v-col>
                            </v-row>
                            </v-container>
                          </section>
                        </vue-html2pdf>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>2. Уровень коммуникативных компетенций сотрудников
                                  </span>
                          <div class="badge green">{{ dashboardData.D2 }}/{{ dashboardData.D2_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.Ds2 }}/{{ dashboardData.D2_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Dr2 }}/{{ dashboardData.D2_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'friendship.communicativeCompetences'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.friendship.communicativeCompetences">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">Сотрудники ЦЗН обладают хорошими коммуникативными навыками (проявление
                                  чуткости, внимательности и т.д.)</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">

                                  <div class="graph-top text-blue">
                                    <div>{{ dashboardData.Ds2 }}<span>/{{ dashboardData.D2_max_value }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ds2')}}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Ds2')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ dashboardData.Dr2 }}<span>/{{ dashboardData.D2_max_value }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{ getAnswersCount('Dr2') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Dr2')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section>
                      </vue-html2pdf>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>3. Открытость руководства
                                  </span>
                          <div class="badge green">{{ dashboardData.D3 }}/{{ dashboardData.D3_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.Ds3 }}/{{ dashboardData.D3_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Dr3 }}/{{ dashboardData.D3_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'friendship.openness'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.friendship.openness">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">Руководство ЦЗН готово идти навстречу для решения моего вопроса.</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">

                                  <div class="graph-top text-blue">
                                    <div>{{ floor2(dashboardData.Ds3 - dashboardData.R) }}<span>/{{ dashboardData.D3_max_value_anket }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ds3') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Ds3')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ floor2(dashboardData.Dr3 - dashboardData.R) }}<span>/{{ dashboardData.D3_max_value_anket }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{getAnswersCount('Dr3')}}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Dr3')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section>
                      </vue-html2pdf>

                      <v-row>
                        <v-col lg="12">
                          <div class="graph-wrap checklist-wrap">
                            <div class="d-flex flex-column">
                              <div>{{ dashboardData.R }}<span>/{{ dashboardData.R_max_value }}</span></div>
                              <p class="mt-auto mb-0">Чек-лист</p>
                            </div>
                            <div class="checklist">
                              <div v-if="checkParameter('Проведение дней, когда руководство «меняется» рабочими местами с сотрудниками приема')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Проведение дней, когда руководство «меняется» рабочими местами с сотрудниками приема
                              </div>
                              <div v-else>
                                <span></span>
                                Проведение дней, когда руководство «меняется» рабочими местами с сотрудниками приема
                              </div>
                              <div v-if="checkParameter('Наличие открытых приемных дней / часов для граждан')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Наличие открытых приемных дней / часов для граждан
                              </div>
                              <div v-else>
                                <span></span>
                                Наличие открытых приемных дней / часов для граждан
                              </div>
                              <div v-if="checkParameter('Проведение публичных мероприятий руководства ЦЗН с гражданами')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Проведение публичных мероприятий руководства ЦЗН с гражданами
                              </div>
                              <div v-else>
                                <span></span>
                                Проведение публичных мероприятий руководства ЦЗН с гражданами
                              </div>
                              <div v-if="checkParameter('Публично доступная электронная почта с возможностью обратиться напрямую к руководителю')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Публично доступная электронная почта с возможностью обратиться напрямую к руководителю
                              </div>
                              <div v-else>
                                <span></span>
                                Публично доступная электронная почта с возможностью обратиться напрямую к руководителю
                              </div>
                              <div v-if="checkParameter('Публично доступный номер мобильного / стационарного телефона руководства')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Публично доступный номер мобильного / стационарного телефона руководства
                              </div>
                              <div v-else>
                                <span></span>
                                Публично доступный номер мобильного / стационарного телефона руководства
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>








        <div class="list-item-wrap">
          <v-expansion-panels multiple :value="expandedPanels">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="list-item">
                  <a class="list-item-name">
                    <button class="toggleSwitch">
                      <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                      </svg>
                      <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                      </svg>
                    </button>
                    <span>2. Показатели полезности обращения в СЗН</span>
                  </a>
                  <div class="badge green"> {{ dashboardData.P_svod }}/{{ dashboardData.P_max_value }}</div>
                  <div class="badge blue">{{ dashboardData.Ps_svod }}/{{ dashboardData.P_max_value }}</div>
                  <div class="badge yellow">{{ dashboardData.Pr_svod }}/{{ dashboardData.P_max_value }}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels multiple :value="expandedPanels">
                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>1. Эффективность / полезность обращения в ЦЗН
                                  </span>
                            <div class="badge green"> {{ dashboardData.P1 }}/{{ dashboardData.P1_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ps1 }}/{{ dashboardData.P1_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Pr1 }}/{{ dashboardData.P1_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'usefulAppeal.usefulCZNAppeal'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.usefulAppeal.usefulCZNAppeal">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">Я считаю свое обращение в ЦЗН полезным</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ dashboardData.Ps1 }}<span>/{{ dashboardData.P1_max_value }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ps1') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ps1')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ dashboardData.Pr1 }}<span>/{{ dashboardData.P1_max_value }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Pr1') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Pr1')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section>
                        </vue-html2pdf>

                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>
                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>2. Динамика роста базы вакансий
                                  </span>
                            <div class="badge dark-blue">{{ dashboardData.Ps2 }}/{{ dashboardData.P2_max_value}}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>{{ computeBaseDynamics() }}</p>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>
                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>3. Проведение мероприятий, стимулирующих рост числа соискателей
                                  </span>
                            <div class="badge dark-blue">{{ dashboardData.Ps3 }}/{{ dashboardData.P3_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Проведено мероприятий, стимулирующих рост числа соискателей: {{ dashboardData.Ps3*5 }}
                          <br>

                        </p>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>










        <div class="list-item-wrap">
          <v-expansion-panels multiple :value="expandedPanels">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="list-item">
                  <a class="list-item-name">
                    <button class="toggleSwitch">
                      <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                      </svg>
                      <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                      </svg>
                    </button>
                    <span>3. Показатели, характеризующие минимизацию усилий клиента</span>
                  </a>
                  <div class="badge green"> {{ dashboardData.M_svod }}/{{ dashboardData.M_svod_max_value }}</div>
                  <div class="badge blue">{{ dashboardData.Ms_svod }}/{{ dashboardData.M_svod_max_value }}</div>
                  <div class="badge yellow">{{ dashboardData.Mr_svod }}/{{ dashboardData.M_svod_max_value }}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels multiple :value="expandedPanels">
                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>1. Доступность и понятность информации о работе, услугах и сервисах СЗН в офисе ЦЗН
                                  </span>
                            <div class="badge green">{{ dashboardData.M1 }}/{{ dashboardData.M1_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ms1 }}/{{ dashboardData.M1_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Mr1 }}/{{ dashboardData.M1_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'effortMinimization.availablitiy'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.effortMinimization.availablitiy">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">В офисе ЦЗН информация о ЦЗН, в т.ч. об услугах и сервисах, сформулирована и представлена в понятной и доступной для меня форме.</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ floor2(dashboardData.Ms1 - dashboardData.DO) }}<span>/{{ dashboardData.M1_max_value_anket }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ms1') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ms1')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ floor2(dashboardData.Mr1 - dashboardData.DO) }}<span>/{{ dashboardData.M1_max_value_anket }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Mr1')}}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Mr1')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section>
                        </vue-html2pdf>

                        <v-row>
                          <v-col lg="12">
                            <div class="graph-wrap checklist-wrap">
                              <div class="d-flex flex-column">
                                <div>{{ dashboardData.DO }}<span>/{{ dashboardData.DO_max_value }}</span></div>
                                <p class="mt-auto mb-0">Чек-лист</p>
                              </div>
                              <div class="checklist">
                                <div v-if="checkParameter('Доступ к информационным стендам/стойкам беспрепятственный (прочесть объявления можно с близкого расстояния, стенды интуитивно легко найти / в ЦЗН есть навигация к инфостендам), в том числе для граждан с инвалидностью')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Доступ к информационным стендам/стойкам беспрепятственный (прочесть объявления можно с близкого расстояния, стенды интуитивно легко найти / в ЦЗН есть навигация к инфостендам), в том числе для граждан с инвалидностью
                                </div>
                                <div v-else>
                                  <span></span>
                                  Доступ к информационным стендам/стойкам беспрепятственный (прочесть объявления можно с близкого расстояния, стенды интуитивно легко найти / в ЦЗН есть навигация к инфостендам), в том числе для граждан с инвалидностью
                                </div>
                                <div v-if="checkParameter('Информация на стендах актуальна на момент оценки по показателю (проверка на предмет актуальности осуществляется не менее чем 1 раз в 3 месяца)\nПоследняя дата проверки актуальности и обновления информации - указывается дата в АИС')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Информация на стендах актуальна на момент оценки по показателю (проверка на предмет актуальности осуществляется не менее чем 1 раз в 3 месяца)\nПоследняя дата проверки актуальности и обновления информации - указывается дата в АИС
                                </div>
                                <div v-else>
                                  <span></span>
                                  Информация на стендах актуальна на момент оценки по показателю (проверка на предмет актуальности осуществляется не менее чем 1 раз в 3 месяца)\nПоследняя дата проверки актуальности и обновления информации - указывается дата в АИС
                                </div>
                                <div v-if="checkParameter('Соблюдение единого оформления стендов в корпоративном стиле (предпочтительно в соответствии с брендбуком «Работа России») в каждом документе на стенде (шрифты, цвета, фирменные элементы и пр.).')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Соблюдение единого оформления стендов в корпоративном стиле (предпочтительно в соответствии с брендбуком «Работа России») в каждом документе на стенде (шрифты, цвета, фирменные элементы и пр.).
                                </div>
                                <div v-else>
                                  <span></span>
                                  Соблюдение единого оформления стендов в корпоративном стиле (предпочтительно в соответствии с брендбуком «Работа России») в каждом документе на стенде (шрифты, цвета, фирменные элементы и пр.).
                                </div>
                                <div v-if="checkParameter('Информация на инфостендах удобно сгруппирована и структурирована по тематическим разделам (информация различной тематики размещена в различных информационных полях (например, объявления о работе ЦЗН и реклама находятся в разных секциях)), есть заголовки')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Информация на инфостендах удобно сгруппирована и структурирована по тематическим разделам (информация различной тематики размещена в различных информационных полях (например, объявления о работе ЦЗН и реклама находятся в разных секциях)), есть заголовки
                                </div>
                                <div v-else>
                                  <span></span>
                                  Информация на инфостендах удобно сгруппирована и структурирована по тематическим разделам (информация различной тематики размещена в различных информационных полях (например, объявления о работе ЦЗН и реклама находятся в разных секциях)), есть заголовки
                                </div>
                                <div v-if="checkParameter('Информационные тексты стенда проанализированы на сайте https://glvrd.ru')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Информационные тексты стенда проанализированы на сайте <a href="https://glvrd.ru">https://glvrd.ru</a>
                                </div>
                                <div v-else>
                                  <span></span>
                                  Информационные тексты стенда проанализированы на сайте <a href="https://glvrd.ru">https://glvrd.ru</a>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>


                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>2. Доступность и понятность информации о работе, услугах и сервисах СЗН на региональном сайте
                                  </span>
                            <div class="badge green">{{ dashboardData.M2 }}/{{ dashboardData.M2_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ms2 }}/{{ dashboardData.M2_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Mr2 }}/{{ dashboardData.M2_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'effortMinimization.siteSZN'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.effortMinimization.siteSZN">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">На региональном сайте СЗН информация о ЦЗН, в т.ч. об услугах и сервисах, сформулирована и представлена в понятной и доступной для меня форме.</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ floor2(dashboardData.Ms2 - dashboardData.DS) }}<span>/{{ dashboardData.M2_max_value_anket }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ms2') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ms2')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ floor2(dashboardData.Mr2 - dashboardData.DS) }}<span>/{{ dashboardData.M2_max_value_anket }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Mr2') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Mr2')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section>
                        </vue-html2pdf>

                        <v-row>
                          <v-col lg="12">
                            <div class="graph-wrap checklist-wrap">
                              <div class="d-flex flex-column">
                                <div>{{ dashboardData.DS }}<span>/{{ dashboardData.DS_max_value }}</span></div>
                                <p class="mt-auto mb-0">Чек-лист</p>
                              </div>
                              <div class="checklist">
                                <div v-if="checkParameter('Сайт СЗН легко найти в популярных поисковиках (Яндекс, Google) (сайт находится в 3 первых позициях поисковой выдачи при введении запросов «ЦЗН региона», «центр занятости региона», «служба занятости региона»)')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Сайт СЗН легко найти в популярных поисковиках (Яндекс, Google) (сайт находится в 3 первых позициях поисковой выдачи при введении запросов «ЦЗН региона», «центр занятости региона», «служба занятости региона»)
                                </div>
                                <div v-else>
                                  <span></span>
                                  Сайт СЗН легко найти в популярных поисковиках (Яндекс, Google) (сайт находится в 3 первых позициях поисковой выдачи при введении запросов «ЦЗН региона», «центр занятости региона», «служба занятости региона»)
                                </div>
                                <div v-if="checkParameter('На сайте имеется минимально необходимая информация о СЗН и ЦЗН, в т.ч. перечень и описание государственных услуг и сервисов в сфере занятости, режимы работы отделений и адреса, контактные данные СЗН и ЦЗН, ссылка на сайт «Работа в России», ссылка на сайт ОИВ, курирующих деятельность государственной службы занятости в  субъекте РФ')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  На сайте имеется минимально необходимая информация о СЗН и ЦЗН, в т.ч. перечень и описание государственных услуг и сервисов в сфере занятости, режимы работы отделений и адреса, контактные данные СЗН и ЦЗН, ссылка на сайт «Работа в России», ссылка на сайт ОИВ, курирующих деятельность государственной службы занятости в  субъекте РФ
                                </div>
                                <div v-else>
                                  <span></span>
                                  На сайте имеется минимально необходимая информация о СЗН и ЦЗН, в т.ч. перечень и описание государственных услуг и сервисов в сфере занятости, режимы работы отделений и адреса, контактные данные СЗН и ЦЗН, ссылка на сайт «Работа в России», ссылка на сайт ОИВ, курирующих деятельность государственной службы занятости в  субъекте РФ
                                </div>
                                <div v-if="checkParameter('Пользователи сайта могут найти минимально необходимую информацию интуитивно и быстро, в т.ч. с помощью навигации')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Пользователи сайта могут найти минимально необходимую информацию интуитивно и быстро, в т.ч. с помощью навигации
                                </div>
                                <div v-else>
                                  <span></span>
                                  Пользователи сайта могут найти минимально необходимую информацию интуитивно и быстро, в т.ч. с помощью навигации
                                </div>
                                <div v-if="checkParameter('Сайт адаптирован для граждан с инвалидностью')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Сайт адаптирован для граждан с инвалидностью
                                </div>
                                <div v-else>
                                  <span></span>
                                  Сайт адаптирован для граждан с инвалидностью
                                </div>
                                <div v-if="checkParameter('Информационные тексты сайта проанализированы на сайте https://glvrd.ru')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Информационные тексты сайта проанализированы на сайте <a href="https://glvrd.ru">https://glvrd.ru</a>
                                </div>
                                <div v-else>
                                  <span></span>
                                  Информационные тексты сайта проанализированы на сайте <a href="https://glvrd.ru">https://glvrd.ru</a>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>

                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>3. Доступность и понятность информации о работе, услугах и сервисах СЗН в социальных стетях
                                  </span>
                            <div class="badge green">{{ dashboardData.M3 }}/{{ dashboardData.M3_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ms3 }}/{{ dashboardData.M3_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Mr3 }}/{{ dashboardData.M3_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'effortMinimization.socialNetwork'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.effortMinimization.socialNetwork">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">В социальных сетях в аккаунтах ЦЗН информация о ЦЗН, в т.ч. об услугах и сервисах, сформулирована и представлена в понятной и доступной для меня форме.</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ floor2(dashboardData.Ms3 - dashboardData.DSS) }}<span>/{{ dashboardData.M3_max_value_anket }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ms3') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ms3')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ floor2(dashboardData.Mr3 - dashboardData.DSS) }}<span>/{{ dashboardData.M3_max_value_anket }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Mr3') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Mr3')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section>
                        </vue-html2pdf>

                        <v-row>
                          <v-col lg="12">
                            <div class="graph-wrap checklist-wrap">
                              <div class="d-flex flex-column">
                                <div>{{ dashboardData.DSS }}<span>/{{ dashboardData.DSS_max_value }}</span></div>
                                <p class="mt-auto mb-0">Чек-лист</p>
                              </div>
                              <div class="checklist">
                                <div v-if="checkParameter('Аккаунты ЦЗН в социальных сетях открыты, не требуют подачу заявок и одобрения со стороны администраторов')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Аккаунты ЦЗН в социальных сетях открыты, не требуют подачу заявок и одобрения со стороны администраторов
                                </div>
                                <div v-else>
                                  <span></span>
                                  Аккаунты ЦЗН в социальных сетях открыты, не требуют подачу заявок и одобрения со стороны администраторов
                                </div>
                                <div v-if="checkParameter('Комментарии к публикациям открыты/есть возможность написать сообществу, при это ЦЗН регулярно и своевременно отвечает на комментарии граждан')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Комментарии к публикациям открыты/есть возможность написать сообществу, при это ЦЗН регулярно и своевременно отвечает на комментарии граждан
                                </div>
                                <div v-else>
                                  <span></span>
                                  Комментарии к публикациям открыты/есть возможность написать сообществу, при это ЦЗН регулярно и своевременно отвечает на комментарии граждан
                                </div>
                                <div v-if="checkParameter('Контент, в т.ч. картинки к постам, оформлен в фирменном стиле «Работа в России», визуально считывается')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Контент, в т.ч. картинки к постам, оформлен в фирменном стиле «Работа в России», визуально считывается
                                </div>
                                <div v-else>
                                  <span></span>
                                  Контент, в т.ч. картинки к постам, оформлен в фирменном стиле «Работа в России», визуально считывается
                                </div>
                                <div v-if="checkParameter('В неделю размещается не менее 3 постов в одной из социальных сетей (указать, в какой)')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  В неделю размещается не менее 3 постов в одной из социальных сетей (указать, в какой)
                                </div>
                                <div v-else>
                                  <span></span>
                                  В неделю размещается не менее 3 постов в одной из социальных сетей (указать, в какой)
                                </div>
                                <div v-if="checkParameter('Информационные тексты аккаунта социальной сети проанализированы на сайте https://glvrd.ru')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Информационные тексты аккаунта социальной сети проанализированы на сайте <a href="https://glvrd.ru">https://glvrd.ru</a>
                                </div>
                                <div v-else>
                                  <span></span>
                                  Информационные тексты аккаунта социальной сети проанализированы на сайте <a href="https://glvrd.ru">https://glvrd.ru</a>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>

                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>4. Доступность и понятность информации о работе, услугах и сервисах СЗН в контакт-центре
                                  </span>
                            <div class="badge green">{{ dashboardData.M4 }}/{{ dashboardData.M4_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ms4 }}/{{ dashboardData.M4_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Mr4 }}/{{ dashboardData.M4_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'effortMinimization.contactCentre'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.effortMinimization.contactCentre">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">В социальных сетях в аккаунтах ЦЗН информация о ЦЗН, в т.ч. об услугах и сервисах, сформулирована и представлена в понятной и доступной для меня форме.</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ floor2(dashboardData.Ms4 - dashboardData.DT) }}<span>/{{ dashboardData.M4_max_value_anket }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ms4') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ms4')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ floor2(dashboardData.Mr4 - dashboardData.DT) }}<span>/{{ dashboardData.M4_max_value_anket }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Dr4') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Dr4')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section>
                        </vue-html2pdf>

                        <v-row>
                          <v-col lg="12">
                            <div class="graph-wrap checklist-wrap">
                              <div class="d-flex flex-column">
                                <div>{{ dashboardData.DT }}<span>/{{ dashboardData.DT_max_value }}</span></div>
                                <p class="mt-auto mb-0">Чек-лист</p>
                              </div>
                              <div class="checklist">
                                <div v-if="checkParameter('Информация о контакт-центре доступна во всех каналах взаимодействия с гражданами (в офисе ЦЗН, на сайте, в социальных сетях), в том числе информация адаптирована для граждан с инвалидностью')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Информация о контакт-центре доступна во всех каналах взаимодействия с гражданами (в офисе ЦЗН, на сайте, в социальных сетях), в том числе информация адаптирована для граждан с инвалидностью
                                </div>
                                <div v-else>
                                  <span></span>
                                  Информация о контакт-центре доступна во всех каналах взаимодействия с гражданами (в офисе ЦЗН, на сайте, в социальных сетях), в том числе информация адаптирована для граждан с инвалидностью
                                </div>
                                <div v-if="checkParameter('Дозвон до контакт-центра составляет не более 3-4 гудков. Количество попыток дозвона не превышает 3 раз')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Дозвон до контакт-центра составляет не более 3-4 гудков. Количество попыток дозвона не превышает 3 раз
                                </div>
                                <div v-else>
                                  <span></span>
                                  Дозвон до контакт-центра составляет не более 3-4 гудков. Количество попыток дозвона не превышает 3 раз
                                </div>
                                <div v-if="checkParameter('Наличие сценариев (скриптов) для операторов контакт-центра по всем услугам и сервисам СЗН (обеспечение точности информации, сценарии проходят проверку на актуальность не реже, чем 1 раз в 3 месяца (необходимо указать дату создания/пересмотра/изменения скриптов и приложить соответствующие подтверждающие документы (при наличии)))')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Наличие сценариев (скриптов) для операторов контакт-центра по всем услугам и сервисам СЗН (обеспечение точности информации, сценарии проходят проверку на актуальность не реже, чем 1 раз в 3 месяца (необходимо указать дату создания/пересмотра/изменения скриптов и приложить соответствующие подтверждающие документы (при наличии)))
                                </div>
                                <div v-else>
                                  <span></span>
                                  Наличие сценариев (скриптов) для операторов контакт-центра по всем услугам и сервисам СЗН (обеспечение точности информации, сценарии проходят проверку на актуальность не реже, чем 1 раз в 3 месяца (необходимо указать дату создания/пересмотра/изменения скриптов и приложить соответствующие подтверждающие документы (при наличии)))
                                </div>
                                <div v-if="checkParameter('При соединении оператор первым поздоровался и представился, в т.ч. озвучил отделение ЦЗН')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  При соединении оператор первым поздоровался и представился, в т.ч. озвучил отделение ЦЗН
                                </div>
                                <div v-else>
                                  <span></span>
                                  При соединении оператор первым поздоровался и представился, в т.ч. озвучил отделение ЦЗН
                                </div>
                                <div v-if="checkParameter('Количество шагов для получения консультации у определенного специалиста не превышает 2 (человеку, чтобы получить необходимую консультацию, нужно пройти не более 2 переключений между различными специалистами ЦЗН)')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Количество шагов для получения консультации у определенного специалиста не превышает 2 (человеку, чтобы получить необходимую консультацию, нужно пройти не более 2 переключений между различными специалистами ЦЗН)
                                </div>
                                <div v-else>
                                  <span></span>
                                  Количество шагов для получения консультации у определенного специалиста не превышает 2 (человеку, чтобы получить необходимую консультацию, нужно пройти не более 2 переключений между различными специалистами ЦЗН)
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>

                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>5. Многоканальность и омниканальность взаимодействия
                                  </span>
                            <div class="badge green">{{ dashboardData.M5 }}/{{ dashboardData.M5_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-row>
                          <vue-html2pdf
                              :show-layout="false"
                              :float-layout="false"
                              :enable-download="true"
                              :preview-modal="false"
                              :paginate-elements-by-height="1400"
                              :filename="userDepartment.name + '.' + 'effortMinimization.omnicanality'"
                              :pdf-quality="2"
                              :manual-pagination="false"
                              pdf-format="a4"
                              pdf-orientation="landscape"
                              pdf-content-width="1400px"
                              ref="html2Pdf.effortMinimization.omnicanality">
                            <section slot="pdf-content">
                              <v-col lg="12">
                                <div class="graph-wrap checklist-wrap">
                                  <div class="d-flex flex-column">
                                    <div>{{ dashboardData.M5 }}<span>/{{ dashboardData.M5_max_value }}</span></div>
                                    <p class="mt-auto mb-0">Чек-лист</p>
                                  </div>
                                  <div class="checklist">
                                    <div v-if="checkParameter('Форма обратной связи на региональном сайте СЗН')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Форма обратной связи на региональном сайте СЗН
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Форма обратной связи на региональном сайте СЗН
                                    </div>
                                    <div v-if="checkParameter('Онлайн-чат с консультантом на региональном сайте СЗН')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Онлайн-чат с консультантом на региональном сайте СЗН
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Онлайн-чат с консультантом на региональном сайте СЗН
                                    </div>
                                    <div v-if="checkParameter('Контакт-центр')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Контакт-центр
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Контакт-центр
                                    </div>
                                    <div v-if="checkParameter('Аккаунты в социальных сетях')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Аккаунты в социальных сетях
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Аккаунты в социальных сетях
                                    </div>
                                    <div v-if="checkParameter('Мессенджеры')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Мессенджеры
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Мессенджеры
                                    </div>
                                    <div v-if="checkParameter('Чат-боты')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Чат-боты
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Чат-боты
                                    </div>
                                    <div v-if="checkParameter('Книга жалоб и предложений')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Книга жалоб и предложений
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Книга жалоб и предложений
                                    </div>
                                    <div v-if="checkParameter('E-mail')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      E-mail
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      E-mail
                                    </div>
                                    <div v-if="checkParameter('Push-рассылка')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      Push-рассылка
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      Push-рассылка
                                    </div>
                                    <div v-if="checkParameter('СМС-информирование и иное (при направлении в адрес ФЦК СЗ ВНИИ труда описания канала взаимодействия, не входящего в перечень)')"
                                         class="checked">
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                            fill="#0033A0"/>
                                      </svg>
                                      СМС-информирование и иное (при направлении в адрес ФЦК СЗ ВНИИ труда описания канала взаимодействия, не входящего в перечень)
                                    </div>
                                    <div v-else>
                                      <span></span>
                                      СМС-информирование и иное (при направлении в адрес ФЦК СЗ ВНИИ труда описания канала взаимодействия, не входящего в перечень)
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                            </section></vue-html2pdf></v-row>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>


                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>6. Быстрое реагирование на запрос клиента
                                  </span>
                            <div class="badge green">{{ dashboardData.M6 }}/{{ dashboardData.M6_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ms6 }}/{{ dashboardData.M6_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Mr6 }}/{{ dashboardData.M6_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'effortMinimization.fastReaction'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.effortMinimization.fastReaction">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">В ЦЗН быстро и оперативно реагируют на мои обращения.</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ floor2(dashboardData.Ms6 - dashboardData.SK) }}<span>/{{ dashboardData.M6_max_value_anket }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ms6') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ms6')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ floor2(dashboardData.Mr6 - dashboardData.SK) }}<span>/{{ dashboardData.SK_max_value }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Mr6') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Mr6')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row></v-container>
                          </section>
                        </vue-html2pdf>

                        <v-row>
                          <v-col lg="12">
                            <div class="graph-wrap checklist-wrap">
                              <div class="d-flex flex-column">
                                <div>{{ dashboardData.SK }}<span>/{{ dashboardData.SK_max_value }}</span></div>
                                <p class="mt-auto mb-0">Чек-лист</p>
                              </div>
                              <div class="checklist">
                                <div v-if="checkParameter('Наличие в ЦЗН системы анализа показателей скорости обслуживания клиентов (замеров времени ожидания в очереди, статистики контакт-центра, времени оказания услуг)')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Наличие в ЦЗН системы анализа показателей скорости обслуживания клиентов (замеров времени ожидания в очереди, статистики контакт-центра, времени оказания услуг)
                                </div>
                                <div v-else>
                                  <span></span>
                                  Наличие в ЦЗН системы анализа показателей скорости обслуживания клиентов (замеров времени ожидания в очереди, статистики контакт-центра, времени оказания услуг)
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>

                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>7. Простота процедур, в т. ч. в части заполнения документов
                                  </span>
                            <div class="badge green">{{ dashboardData.M7 }}/{{ dashboardData.M7_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ms7 }}/{{ dashboardData.M7_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Mr7 }}/{{ dashboardData.M7_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'effortMinimization.easyProcedure'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.effortMinimization.easyProcedure">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">Мне было просто пройти все процедуры в ЦЗН (например, заполнить документы и пр.).</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ dashboardData.Ms7 }}<span>/{{ dashboardData.M7_max_value }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ms7') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ms7')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ dashboardData.Mr7 }}<span>/{{ dashboardData.M7_max_value }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Mr7') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Mr7')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section></vue-html2pdf>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>

                  <v-expansion-panel class="level2">
                    <div class="list-item-wrap">
                      <v-expansion-panel-header @keyup.space.prevent>
                        <div class="list-item">
                          <a class="list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <span>8. Возможность предварительной записи на прием
                                  </span>
                            <div class="badge green">{{ dashboardData.M8 }}/{{ dashboardData.M8_max_value }}</div>
                            <div class="badge blue">{{ dashboardData.Ms8 }}/{{ dashboardData.M8_max_value }}</div>
                            <div class="badge yellow">{{ dashboardData.Mr8 }}/{{ dashboardData.M8_max_value }}</div>
                          </a>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="userDepartment.name + '.' + 'effortMinimization.preEntryFunction'"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"
                            pdf-orientation="landscape"
                            pdf-content-width="1020px"
                            ref="html2Pdf.effortMinimization.preEntryFunction">
                          <section slot="pdf-content">
                            <v-container>
                              <v-row>
                                <v-col lg="2" class="d-flex flex-column">
                                  <p class="mt-auto mb-0">Функционал предварительной записи в ЦЗН удобен и прост для меня.</p>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">

                                    <div class="graph-top text-blue">
                                      <div>{{ floor2(dashboardData.Ms8 - dashboardData.PZ) }}<span>/{{ dashboardData.M8_max_value_anket }}</span></div>
                                      <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ms8') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Ms8')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="5">
                                  <div class="graph-wrap">
                                    <div class="graph-top text-orange">
                                      <div>{{ floor2(dashboardData.Mr8 - dashboardData.PZ) }}<span>/{{ dashboardData.M8_max_value_anket }}</span></div>
                                      <div>Работодатели <span class="text-blue">{{ getAnswersCount('Mr8') }}</span></div>
                                    </div>
                                    <GChart
                                        type="ColumnChart"
                                        :data="getDiagramData('Mr8')"
                                        :options="columnChartOptions"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </section></vue-html2pdf>
                        <v-row>
                          <v-col lg="12">
                            <div class="graph-wrap checklist-wrap">
                              <div class="d-flex flex-column">
                                <div>{{ dashboardData.PZ }}<span>/{{ dashboardData.PZ_max_value }}</span></div>
                                <p class="mt-auto mb-0">Чек-лист</p>
                              </div>
                              <div class="checklist">
                                <div v-if="checkParameter('Офис ЦЗН')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Офис ЦЗН
                                </div>
                                <div v-else>
                                  <span></span>
                                  Офис ЦЗН
                                </div>
                                <div v-if="checkParameter('Сайт региональной СЗН')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Сайт региональной СЗН
                                </div>
                                <div v-else>
                                  <span></span>
                                  Сайт региональной СЗН
                                </div>
                                <div v-if="checkParameter('Аккаунты ЦЗН в социальных сетях')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Аккаунты ЦЗН в социальных сетях
                                </div>
                                <div v-else>
                                  <span></span>
                                  Аккаунты ЦЗН в социальных сетях
                                </div>
                                <div v-if="checkParameter('Контакт-центр ЦЗН')"
                                     class="checked">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                        fill="#0033A0"/>
                                  </svg>
                                  Контакт-центр ЦЗН
                                </div>
                                <div v-else>
                                  <span></span>
                                  Контакт-центр ЦЗН
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>



      <div class="list-item-wrap">
        <v-expansion-panels multiple :value="expandedPanels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="list-item">
                <a class="list-item-name">
                  <button class="toggleSwitch">
                    <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                    </svg>
                    <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                    </svg>
                  </button>
                  <span>4. Показатели адресности и персонализации взаимодействия с клиентом</span>
                </a>
                <div class="badge green"> {{ dashboardData.A_svod }}/{{ dashboardData.A_max_value }}</div>
                <div class="badge blue">{{ dashboardData.As_svod }}/{{ dashboardData.A_max_value }}</div>
                <div class="badge yellow">{{ dashboardData.Ar_svod }}/{{ dashboardData.A_max_value }}</div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels multiple :value="expandedPanels">
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>1. Наличие персонального консультанта
                                  </span>
                          <div class="badge green"> {{ dashboardData.A1 }}/{{ dashboardData.A1_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.As1 }}/{{ dashboardData.A1_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Ar1 }}/{{ dashboardData.A1_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'personalization.curator'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.personalization.curator">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">В ЦЗН быстро и оперативно реагируют на мои обращения.</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">

                                  <div class="graph-top text-blue">
                                    <div>{{ dashboardData.As1 }}<span>/{{ dashboardData.A1_max_value }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('As1') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData1('As1')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ dashboardData.Ar1 }}<span>/{{ dashboardData.A1_max_value }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{ getAnswersCount('Ar1') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData1('Ar1')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section></vue-html2pdf>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>2. Учет личных обстоятельств
                                  </span>
                          <div class="badge green"> {{ dashboardData.A2 }}/{{ dashboardData.A2_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.As2 }}/{{ dashboardData.A2_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Ar2 }}/{{ dashboardData.A2_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'personalization.privateCircumstances'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.personalization.privateCircumstances">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">Мои личные обстоятельства были учтены сотрудниками ЦЗН при оказании услуг.</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-blue">
                                    <div>{{ dashboardData.As2 }}<span>/{{ dashboardData.A2_max_value }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('As2') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('As2')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ dashboardData.Ar2 }}<span>/{{ dashboardData.A2_max_value }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{ getAnswersCount('Ar2')}}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Ar2')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section></vue-html2pdf>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>




      <div class="list-item-wrap">
        <v-expansion-panels multiple :value="expandedPanels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="list-item">
                <a class="list-item-name">
                  <button class="toggleSwitch">
                    <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                    </svg>
                    <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                    </svg>
                  </button>
                  <span>5. Показатели внешнего комфорта и удобства</span>
                </a>
                <div class="badge green"> {{ dashboardData.K_svod }}/{{ dashboardData.K_max_value }}</div>
                <div class="badge blue">{{ dashboardData.Ks_svod }}/{{ dashboardData.K_max_value }}</div>
                <div class="badge yellow">{{ dashboardData.Kr_svod }}/{{ dashboardData.K_max_value }}</div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels multiple :value="expandedPanels">
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>1. Внешний вид сотрудников
                                  </span>
                          <div class="badge green"> {{ dashboardData.K1 }}/{{ dashboardData.K1_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.Ks1 }}/{{ dashboardData.K1_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Kr1 }}/{{ dashboardData.K1_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'comfort.appereance'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.comfort.appereance">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">Внешний вид сотрудников ЦЗН соответствует деловому стилю.</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-blue">
                                    <div>{{ floor2(dashboardData.Ks1 - dashboardData.VV) }}<span>/{{ dashboardData.K1_max_value_anket }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ks1') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Ks1')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ floor2(dashboardData.Kr1 - dashboardData.VV) }}<span>/{{ dashboardData.K1_max_value_anket }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{ getAnswersCount('Kr1') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Kr1')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section></vue-html2pdf>
                      <v-row>
                        <v-col lg="12">
                          <div class="graph-wrap checklist-wrap">
                            <div class="d-flex flex-column">
                              <div>{{ dashboardData.VV }}<span>/{{ dashboardData.VV_max_value }}</span></div>
                              <p class="mt-auto mb-0">Чек-лист</p>
                            </div>
                            <div class="checklist">
                              <div v-if="checkParameter('Белый верх, черный или темно-синий низ униформы (п. 5.1 Брендбука) (блуза/рубашка с длинным рукавом, юбка / брюки прямого кроя, колготки телесного цвета, если туфли – то закрытые, для мужчин допустимы только классические ботинки)')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Белый верх, черный или темно-синий низ униформы (п. 5.1 Брендбука) (блуза/рубашка с длинным рукавом, юбка / брюки прямого кроя, колготки телесного цвета, если туфли – то закрытые, для мужчин допустимы только классические ботинки)
                              </div>
                              <div v-else>
                                <span></span>
                                Белый верх, черный или темно-синий низ униформы (п. 5.1 Брендбука) (блуза/рубашка с длинным рукавом, юбка / брюки прямого кроя, колготки телесного цвета, если туфли – то закрытые, для мужчин допустимы только классические ботинки)
                              </div>
                              <div v-if="checkParameter('Наличие платка для женщин, галстука/бабочки у мужчин, нагрудного значка с фирменным паттерном «Работа в России» (п. 5.1 Брендбука)')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Наличие платка для женщин, галстука/бабочки у мужчин, нагрудного значка с фирменным паттерном «Работа в России» (п. 5.1 Брендбука)
                              </div>
                              <div v-else>
                                <span></span>
                                Наличие платка для женщин, галстука/бабочки у мужчин, нагрудного значка с фирменным паттерном «Работа в России» (п. 5.1 Брендбука)
                              </div>
                              <div v-if="checkParameter('Макияж сотрудников не должен быть слишком ярким, используемые духи не должны иметь резкий запах')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Макияж сотрудников не должен быть слишком ярким, используемые духи не должны иметь резкий запах
                              </div>
                              <div v-else>
                                <span></span>
                                Макияж сотрудников не должен быть слишком ярким, используемые духи не должны иметь резкий запах
                              </div>
                              <div v-if="checkParameter('Волосы должны быть аккуратно подстрижены и расчесаны или убраны в хвост')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Волосы должны быть аккуратно подстрижены и расчесаны или убраны в хвост
                              </div>
                              <div v-else>
                                <span></span>
                                Волосы должны быть аккуратно подстрижены и расчесаны или убраны в хвост
                              </div>
                              <div v-if="checkParameter('Руки должны быть чистыми, ногти аккуратно подстрижены (разумной длины)')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Руки должны быть чистыми, ногти аккуратно подстрижены (разумной длины)
                              </div>
                              <div v-else>
                                <span></span>
                                Руки должны быть чистыми, ногти аккуратно подстрижены (разумной длины)
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>2. Согласованность действий между подразделениями СЗН
                                  </span>
                          <div class="badge green"> {{ dashboardData.K2 }}/{{ dashboardData.K2_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.Ks2 }}/{{ dashboardData.K2_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Kr2 }}/{{ dashboardData.K2_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'comfort.actions'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.comfort.actions">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">Действия сотрудников ЦЗН между собой были согласованы и слажены.</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-blue">
                                    <div>{{ dashboardData.Ks2 }}<span>/{{ dashboardData.K2_max_value }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ks2') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Ks2')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ dashboardData.Kr2 }}<span>/{{ dashboardData.K2_max_value }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{ getAnswersCount('Kr2') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Kr2')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section></vue-html2pdf>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>3. Транспортная доступность
                                  </span>
                          <div class="badge green"> {{ dashboardData.K3 }}/{{ dashboardData.K3_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.Ks3 }}/{{ dashboardData.K3_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Kr3 }}/{{ dashboardData.K3_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'comfort.easyToGetThere'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.comfort.easyToGetThere">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">Мне было легко добраться до ЦЗН.</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-blue">
                                    <div>{{ floor2(dashboardData.Ks3 - dashboardData.TrD) }}<span>/{{ dashboardData.K3_max_value_anket }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ks3') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Ks3')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ floor2(dashboardData.Kr3 - dashboardData.TrD) }}<span>/{{ dashboardData.K3_max_value_anket }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{ getAnswersCount('Kr3') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Kr3')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section></vue-html2pdf>
                      <v-row>
                        <v-col lg="12">
                          <div class="graph-wrap checklist-wrap">
                            <div class="d-flex flex-column">
                              <div>{{ dashboardData.TrD }}<span>/{{ dashboardData.TrD_max_value }}</span></div>
                              <p class="mt-auto mb-0">Чек-лист</p>
                            </div>
                            <div class="checklist">
                              <div v-if="checkParameter('Офис/точка присутствия находится в пешей доступности от остановок общественного транспорта (не более 15 минут)')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Офис/точка присутствия находится в пешей доступности от остановок общественного транспорта (не более 15 минут)
                              </div>
                              <div v-else>
                                <span></span>
                                Офис/точка присутствия находится в пешей доступности от остановок общественного транспорта (не более 15 минут)
                              </div>
                              <div v-if="checkParameter('Наличие навигации к ЦЗН на остановках общественного транспорта/в значимых общественных местах')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Наличие навигации к ЦЗН на остановках общественного транспорта/в значимых общественных местах
                              </div>
                              <div v-else>
                                <span></span>
                                Наличие навигации к ЦЗН на остановках общественного транспорта/в значимых общественных местах
                              </div>
                              <div v-if="checkParameter('Наличие доступной парковки на территории ЦЗН')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Наличие доступной парковки на территории ЦЗН
                              </div>
                              <div v-else>
                                <span></span>
                                Наличие доступной парковки на территории ЦЗН
                              </div>
                              <div v-if="checkParameter('Наличие парковочных мест для инвалидов на территории ЦЗН')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Наличие парковочных мест для инвалидов на территории ЦЗН
                              </div>
                              <div v-else>
                                <span></span>
                                Наличие парковочных мест для инвалидов на территории ЦЗН
                              </div>
                              <div v-if="checkParameter('Доступная среда для граждан с инвалидностью- пандусы, кнопки вызова сотрудников, визуальные метки, навигационные таблички со шрифтом Брайля')"
                                   class="checked">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13 4.19747C13 4.40393 12.9277 4.57943 12.7832 4.72397L7.17806 10.3291L6.12516 11.382C5.98065 11.5265 5.80516 11.5988 5.59871 11.5988C5.39226 11.5988 5.21677 11.5265 5.07226 11.382L4.01935 10.3291L1.21677 7.52647C1.07226 7.382 1 7.20653 1 7.00007C1 6.7936 1.07226 6.6181 1.21677 6.47357L2.26968 5.42067C2.41419 5.2762 2.58968 5.20397 2.79613 5.20397C3.00258 5.20397 3.17807 5.2762 3.32258 5.42067L5.59871 7.70457L10.6774 2.61817C10.8219 2.47363 10.9974 2.40137 11.2039 2.40137C11.4103 2.40137 11.5858 2.47363 11.7303 2.61817L12.7832 3.67107C12.9277 3.81553 13 3.991 13 4.19747Z"
                                      fill="#0033A0"/>
                                </svg>
                                Доступная среда для граждан с инвалидностью- пандусы, кнопки вызова сотрудников, визуальные метки, навигационные таблички со шрифтом Брайля
                              </div>
                              <div v-else>
                                <span></span>
                                Доступная среда для граждан с инвалидностью- пандусы, кнопки вызова сотрудников, визуальные метки, навигационные таблички со шрифтом Брайля
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
                <v-expansion-panel class="level2">
                  <div class="list-item-wrap">
                    <v-expansion-panel-header @keyup.space.prevent>
                      <div class="list-item">
                        <a class="list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>4. Комфортность условий для получения услуг и сервисов
                                  </span>
                          <div class="badge green"> {{ dashboardData.K4 }}/{{ dashboardData.K4_max_value }}</div>
                          <div class="badge blue">{{ dashboardData.Ks4 }}/{{ dashboardData.K4_max_value }}</div>
                          <div class="badge yellow">{{ dashboardData.Kr4 }}/{{ dashboardData.K4_max_value }}</div>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <vue-html2pdf
                          :show-layout="false"
                          :float-layout="false"
                          :enable-download="true"
                          :preview-modal="false"
                          :paginate-elements-by-height="1400"
                          :filename="userDepartment.name + '.' + 'comfort.comfortResidence'"
                          :pdf-quality="2"
                          :manual-pagination="false"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="1020px"
                          ref="html2Pdf.comfort.comfortResidence">
                        <section slot="pdf-content">
                          <v-container>
                            <v-row>
                              <v-col lg="2" class="d-flex flex-column">
                                <p class="mt-auto mb-0">Мое пребывание в ЦЗН было комфортным (помещение чистое и удобное).</p>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-blue">
                                    <div>{{ floor2(dashboardData.Ks4 - dashboardData.KP) }}<span>/{{ dashboardData.K4_max_value_anket }}</span></div>
                                    <div>Соискатели <span class="text-blue">{{ getAnswersCount('Ks4') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Ks4')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                              <v-col lg="5">
                                <div class="graph-wrap">
                                  <div class="graph-top text-orange">
                                    <div>{{ floor2(dashboardData.Kr4 - dashboardData.KP) }}<span>/{{ dashboardData.K4_max_value_anket }}</span></div>
                                    <div>Работодатели <span class="text-blue">{{ getAnswersCount('Kr4') }}</span></div>
                                  </div>
                                  <GChart
                                      type="ColumnChart"
                                      :data="getDiagramData('Kr4')"
                                      :options="columnChartOptions"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </section></vue-html2pdf>
                      <v-row>
                        <v-col lg="12">
                          <div class="graph-wrap checklist-wrap">
                            <div class="d-flex flex-column">
                              <div>{{ dashboardData.KP }}<span>/{{ dashboardData.KP_max_value }}</span></div>
                              <p class="mt-auto mb-0">Чек-лист</p>
                            </div>
                            <div class="checklist">
                              <div>
                                <span></span>
                                Уровень комфортности: {{getLevelValue()}} <br>
                                Уровень 3: {{getLevelValueConsts().l3}} / {{getLevelValueConsts().level3Indices.length}} <br>
                                Уровень 2: {{getLevelValueConsts().l2}} / {{getLevelValueConsts().level2Indices.length}}<br>
                                Уровень 1: {{getLevelValueConsts().l1}} / {{getLevelValueConsts().level1Indices.length}}<br>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </div>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>



    </div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="userDepartment.name + '.' + 'demographic'"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="100%"
            ref="html2Pdf.demographic">
          <section slot="pdf-content">
            <h2 style="margin-top: 70px;">Социально-демографическая характеристика опрошенных</h2>

            <v-tabs>
              <v-tab>пол</v-tab>
              <v-tab>возраст</v-tab>
              <v-tab>категории граждан</v-tab>

              <v-tab-item>
                <GChart
                    type="ColumnChart"
                    :data="getGenderChartData()"
                    :options="genderChartOptions"
                />
              </v-tab-item>

              <v-tab-item>
                <GChart
                    type="BarChart"
                    :data="getAgechartData()"
                    :options="ageChartOptions"
                />
              </v-tab-item>

              <v-tab-item>
                <GChart
                    type="BarChart"
                    :data="getCategoriesChartData()"
                    :options="categoriesChartOptions"
                />
              </v-tab-item>
            </v-tabs>
          </section>
        </vue-html2pdf>
        <h2 style="margin-top: 60px;">Динамика изменения</h2>

        <v-row>
          <v-col lg="6">


            <p>Период сбора информации</p>
            <v-row>
              <v-col lg="6">

                <v-menu v-model="startDateMenu"
                        :close-on-content-click="false"
                        min-width="auto"
                        offset-y
                        transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        prepend-icon="mdi-calendar"
                        readonly
                        :value="dynamicStartDate | dateFormat"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      locale="ru-RU"
                      no-title
                      scrollable
                      :max="maxDate"
                      v-model="dynamicStartDate"
                      @input="startDateMenu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col lg="6">
                <v-menu v-model="endDateMenu"
                        :close-on-content-click="false"
                        min-width="auto"
                        offset-y
                        transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        prepend-icon="mdi-calendar"
                        readonly
                        :value="dynamicEndDate | dateFormat"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      locale="ru-RU"
                      no-title
                      scrollable
                      :max="maxDate"
                      v-model="dynamicEndDate"
                      @input="endDateMenu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-select label="Группа показателей"
                      :items="dynamicIndexGroups"
                      item-text="title"
                      return-object
                      clearable
                      v-model="dynamicIndexGroup"></v-select>

            <v-select v-if="dynamicIndexGroup != null"
                      label="Показатель"
                      :items="dynamicIndexGroup.indices"
                      item-text="title"
                      item-value="value"
                      clearable
                      v-model="dynamicIndex"></v-select>
            <v-select v-else
                      label="Показатель"
                      disabled></v-select>

            <v-select label="Тип"
                      :items="dynamicTypes"
                      item-text="title"
                      item-value="value"
                      v-model="dynamicType"></v-select>
            <v-btn class="btn btn-primary"
                   @click="getDynamicDiagram()">
              Применить
            </v-btn>
          </v-col>
          <v-col v-if="chartData != null" lg="6">
            <div class="line-wrap">
              <vue-html2pdf
                  :show-layout="false"
                  :float-layout="false"
                  :enable-download="true"
                  :preview-modal="false"
                  :paginate-elements-by-height="1400"
                  :filename="userDepartment.name + '.' + 'dynamic'"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="1400px"
                  ref="html2Pdf.dynamic">
                <section slot="pdf-content">
                  <GChart
                      type="LineChart"
                      :data="chartData"
                      :options="chartOptions"
                  />
                </section>
              </vue-html2pdf>
            </div>
          </v-col>

        </v-row>
      </template>
  </div>
</template>

<script>
import {GChart} from "vue-google-charts/legacy";
import VueHtml2pdf from 'vue-html2pdf';
import DashboardExportDialog from "@/components/dialogs/DashboardExportDialog";
import {
  anketRabString,
  anketSoiskString, checklistQuestions,
  cznIccDataString, genderAnswers, iccDataChecklistsString, questionsDict,
  regionIccDataString,
  roundNumericFields
} from "@/modules/IccData";

export default {
  name: "ClientCentricityMain",
  components: {
    DashboardExportDialog,
    GChart,
    VueHtml2pdf
  },
  props: ['userRegion', 'userDepartment'],
  watch : {
    userRegion: async function () {
      await this.initializeMockData();
      this.$forceUpdate();
    },

    userDepartment: async function () {
      await this.initializeMockData();
      this.$forceUpdate();
    }
  },

  data: () => ({
    cznIccData: JSON.parse(cznIccDataString),
    regionIccData: JSON.parse(regionIccDataString),
    soiskAnswers: JSON.parse(anketSoiskString),
    rabAnswers: JSON.parse(anketRabString),
    iccDataChecklists: JSON.parse(iccDataChecklistsString),
    cznSoiskAnswers: [],
    cznRabAnswers: [],
    indexMaxValues_terr_dec: {
      I_max_value: 50,
      Ir_max_value: 50,
      Is_max_value: 50,
      D_max_value: 16,
      D1_max_value: 3,
      D2_max_value: 3,
      D3_max_value: 2,
      R_max_value: 1,
      D3_max_value_anket: 1,
      P_max_value: 6,
      P1_max_value: 2,
      P2_max_value: 2,
      P3_max_value: 2,
      M_svod_max_value:16,
      M1_max_value: 2,
      DO_max_value:1,
      M1_max_value_anket:1,
      M2_max_value:2,
      M2_max_value_anket:1,
      DS_max_value: 1,
      M3_max_value:2,
      M3_max_value_anket:1,
      DSS_max_value:1,
      M4_max_value:2,
      M4_max_value_anket:1,
      DT_max_value:1,
      M5_max_value:1,
      M6_max_value:2,
      M6_max_value_anket:1,
      SK_max_value:1,
      M7_max_value:3,
      M8_max_value:10,
      M8_max_value_anket:2,
      PZ_max_value:1,
      A_max_value: 4,
      A1_max_value:2,
      A2_max_value:2,
      K_max_value:8,
      K1_max_value:2,
      K1_max_value_anket:1,
      VV_max_value:1,
      K2_max_value:1,
      K3_max_value:2,
      K3_max_value_anket:1,
      TrD_max_value:1,
      K4_max_value:3,
      K4_max_value_anket:1,
      KP_max_value:2
    },

    indexMaxValues_upr: {
      I_max_value: 14,
      Ir_max_value: 14,
      Is_max_value: 14,
      M_svod_max_value:12,
      M2_max_value:2,
      M2_max_value_anket:1,
      DS_max_value: 1,
      M3_max_value:2,
      M3_max_value_anket:1,
      DSS_max_value:1,
      M4_max_value:2,
      M4_max_value_anket:1,
      DT_max_value:1,
      M5_max_value:1,
      M7_max_value:3,
      M8_max_value:10,
      M8_max_value_anket:2,
      PZ_max_value:1,
      K_max_value:2,
      K3_max_value:2,
      K3_max_value_anket:1,
      TrD_max_value:1,
    },

    indexMaxValues_terr_cen: {
      I_max_value: 36,
      Ir_max_value: 36,
      Is_max_value: 36,
      D_max_value: 16,
      D1_max_value: 3,
      D2_max_value: 3,
      D3_max_value: 2,
      R_max_value: 1,
      D3_max_value_anket: 1,
      P_max_value: 6,
      P1_max_value: 2,
      P2_max_value: 2,
      P3_max_value: 2,
      M_svod_max_value:4,
      M1_max_value: 2,
      DO_max_value:1,
      M1_max_value_anket:1,
      M6_max_value:2,
      M6_max_value_anket:1,
      SK_max_value:1,
      A_max_value: 4,
      A1_max_value:2,
      A2_max_value:2,
      K_max_value:6,
      K1_max_value:2,
      K1_max_value_anket:1,
      VV_max_value:1,
      K2_max_value:1,
      K4_max_value:3,
      K4_max_value_anket:1,
      KP_max_value:2
    },

    apiLoaded: false,
    show: false,
    maxDate: new Date().toJSON().slice(0,10),
    exportDialogKey: 0,

    dashboardExportDialog: false,

    expandedPanels: [0, 1, 2, 3, 4, 5, 6, 7, 8],

    currentQuarter: {value: null, year: null},

    regionView: false,

    config: null,
    configId: null,

    formData: null,
    parametersTitleMap: new Map(),
    parametersAnswerStatusesPartMap: new Map(),
    parametersTextPartMap: new Map(),

    componentKey: 0,

    startDateMenu: false,
    endDateMenu: false,

    dynamicStartDate: null,
    dynamicEndDate: null,

    dynamicTypes:[
      {title : 'Индекс клиентоцентричности', value: 'valueSummary'},
      {title : 'Индекс клиентоцентричности с точки зрения соискателей', value: 'valueApplicant'},
      {title : 'Индекс клиентоцентричности с точки зрения работодателей', value: 'valueEmployer'},
    ],
    dynamicType: 'valueSummary',
    dynamicIndexGroups: [
        {title: 'Показатели дружелюбия и партнерства', value: 'friendship',
          indices: [
              {title: 'Уровень профессиональных компетенций сотрудников', value: 'professionalCompetences', haveDashboard: true},
              {title: 'Уровень коммуникативных компетенций сотрудников', value: 'communicativeCompetences', haveDashboard: true},
              {title: 'Открытость руководства', value: 'openness', haveDashboard: true},
          ]},
      {title: 'Показатели полезности обращения в СЗН', value: 'usefulAppeal',
        indices: [
          {title: 'Эффективность / полезность обращения в ЦЗН', value: 'usefulCZNAppeal', haveDashboard: true},
          {title: 'Динамика роста базы вакансий', value: 'vacancyBaseRate', haveDashboard: false},
          {title: 'Проведение мероприятий, стимулирующих рост числа соискателей', value: 'eventsHosting', haveDashboard: false},
        ]},
      {title: 'Показатели, характеризующие минимизацию усилий клиента', value: 'effortMinimization',
        indices: [
          {title: 'Доступность и понятность информации о сервисах и СЗН в офисе ЦЗН', value: 'availablitiy', haveDashboard: true},
          {title: 'Доступность и понятность информации о сервисах и СЗН на сайте региональной СЗН', value: 'siteSZN', haveDashboard: true},
          {title: 'Доступность и понятность информации о сервисах и СЗН в социальных сетях', value: 'socialNetwork', haveDashboard: true},
          {title: 'Доступность и понятность информации о сервисах и СЗН в контакт центре', value: 'contactCentre', haveDashboard: true},
          {title: 'Многоканальность и омниканальность взаимодействия', value: 'omnicanality', haveDashboard: false},
          {title: 'Простота процедур, в т.ч. в части заполнения документов', value: 'easyProcedure', haveDashboard: true},
          {title: 'Быстрое реагирование на запрос клиента', value: 'fastReaction', haveDashboard: true},
          {title: 'Возможность предварительной записи на прием', value: 'preEntryFunction', haveDashboard: true},
        ]},
      {title: 'Показатели адресности и персонализации взаимодействия с клиентом', value: 'personalization',
        indices: [
          {title: 'Наличие персонального консультанта', value: 'curator', haveDashboard: true},
          {title: 'Учет личных обстоятельств', value: 'privateCircumstances', haveDashboard: true},
        ]},
      {title: 'Показатели внешнего комфорта и удобства', value: 'comfort',
        indices: [
          {title: 'Комфортность условий для получения услуг и сервисов', value: 'comfortResidence', haveDashboard: true},
          {title: 'Согласованность действий между подразделениями СЗН (глазами клиента)', value: 'actions', haveDashboard: true},
          {title: 'Транспортная доступность', value: 'easyToGetThere', haveDashboard: true},
          {title: 'Внешний вид сотрудников', value: 'appereance', haveDashboard: true},
        ]},
    ],
    dynamicIndexGroup: null,
    dynamicIndex: null,

    columnChartOptions: {
      backgroundColor: 'transparent',
      legend: { position: "none" },
      chartArea: {width:'100%',left:0,top:0,height:150},
      vAxis: { gridlines: {
          color: 'transparent'
        },
        textPosition: 'none'
      },
      hAxis: {color: '#333', minSpacing: 20},
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#000',
        }
      },
      bar: {
        groupWidth: 30
      }
    },

    ageChartOptions: {
      backgroundColor: 'transparent',
      legend: { position: "none" },
      chartArea: {width:'100%',left:100,top:10,height:300},
      height: 380,
      vAxis: { minValue: 0,
        textStyle : {
          fontSize: 10
        }
      },
      hAxis: {color: '#333'},
      pointSize: 7,
      annotations: {
        textStyle: {
          fontSize: 12,
          color: '#000',
        }
      },
      series: {
        0:{color:'#69B3E7'},
      }
    },

    categoriesChartOptions: {
      backgroundColor: 'transparent',
      legend: { position: "none" },
      chartArea: {width:'100%',left:450,top:10,height:400},
      height: 480,
      vAxis: { minValue: 0, maxValue: 120,
        textStyle : {
          fontSize: 10
        }
      },
      hAxis: {color: '#333'},
      pointSize: 7,
      annotations: {
        textStyle: {
          fontSize: 12,
          color: '#000',
        }
      },
      series: {
        0:{color:'#69B3E7'},
      }

    },

    chartOptions: {
      backgroundColor: 'transparent',
      legend: { position: "none" },
      chartArea: {width:'100%',left:80,top:30,height:300},
      width: 500,
      height: 380,
      vAxis: { minValue: 0, maxValue: 45, },
      hAxis: {color: '#333', minSpacing: 20},
      pointSize: 7,
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#000',
        }
      },

    },

    chartData: null,

    genderChartOptions: {
      backgroundColor: 'transparent',
      legend: { position: "none" },
      height: 380,
      chartArea: {width:'100%',left:0,top:0,height:250},
      vAxis: { gridlines: {
          color: 'transparent'
        },
        textPosition: 'none'
      },
      hAxis: {color: '#333', minSpacing: 20},
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#000',
        }
      },
      bar: {
        groupWidth: 100
      },
      series: {
        0:{color:'#69B3E7'},
        1:{color:'#69B3E7'},
        2:{color:'#69B3E7'},
      }
    },

  }),

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  methods: {
    async getActiveConfig() {
      let req = await this.$getApi("/clientCentricity/config/getActiveConfig")
      if (req.ok) {
        this.config = req.payload;
        this.configId = this.config.id;
        this.config.configParams = JSON.parse(this.config.clientCentricityJson);
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadCheckListValues() {
      let req = await this.$getApi("/clientCentricity/formData/getLastFormData",
          {configId: this.configId, region: this.userRegion.name, department: this.userDepartment.name});
      if (req.ok) {
        this.formData = req.payload;
        this.getParametersDataMaps();
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadAnotherCheckListValues(parameter) {
      let req = await this.$getApi("/clientCentricity/formData/getFormData",
          {formDataId: this.formData[parameter].id});
      if (req.ok) {
        this.formData = req.payload;

        this.parametersTextPartMap = new Map();
        this.parametersAnswerStatusesPartMap = new Map();

        this.getParametersDataMaps();
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    getParametersDataMaps() {
      /*
       * TODO Много бойлералейта. Переписать
       * */
      let parametersTitleArray = Object.entries(this.formData.parametersTitle).map((arr) => ({
        parameterName: arr[0],
        value: arr[1],
      }));
      let parametersAnswerStateArray = Object.entries(this.formData.parametersAnswerState).map((arr) => ({
        parameterName: arr[0],
        value: arr[1],
      }));
      let parametersTextPartArray = Object.entries(this.formData.parametersTextPart).map((arr) => ({
        parameterName: arr[0],
        value: arr[1],
      }));

      for (let parameterTitle of parametersTitleArray) {
        this.parametersTitleMap.set(parameterTitle.parameterName, parameterTitle.value);
      }
      for (let parameterAnswerState of parametersAnswerStateArray) {
        this.parametersAnswerStatusesPartMap.set(parameterAnswerState.parameterName, parameterAnswerState.value);
      }
      for (let parameterTextPart of parametersTextPartArray) {
        this.parametersTextPartMap.set(parameterTextPart.parameterName, parameterTextPart.value);
      }
    },

    async loadDashboardData() {
      let req;
      if (this.userDepartment === 'По всем ЦЗН региона') {
        this.regionView = true;
        req = await this.$getApi("/icc/region/get",
            {
              quarter: this.currentQuarter.value,
              year: this.currentQuarter.year,
              regionCode: this.userRegion.code,
              clientCentricityConfigId: this.configId
            });
      } else {
        this.regionView = false;
        if (this.userRegion.netType === 'DECENTRALIZED') {
          req = await this.$getApi("/icc/index/get",
              {
                quarter: this.currentQuarter.value,
                year: this.currentQuarter.year,
                cznId: this.userDepartment.id,
                clientCentricityConfigId: this.configId
              });
        } else if (this.userRegion.netType === 'CENTRALIZED') {
          if (this.userDepartment.type === 'territorial_czn') {
            req = await this.$getApi("/icc/index/territorial/get",
                {
                  quarter: this.currentQuarter.value,
                  year: this.currentQuarter.year,
                  cznId: this.userDepartment.id,
                  clientCentricityConfigId: this.configId
                });
          } else if (this.userDepartment.type === 'manager_czn') {
            req = await this.$getApi("/icc/index/manager/get",
                {
                  quarter: this.currentQuarter.value,
                  year: this.currentQuarter.year,
                  cznId: this.userDepartment.id,
                  clientCentricityConfigId: this.configId
                });
          }
        }
      }
      if (req.ok) {
        this.dashboardData = req.payload;
        Object.assign(this.dashboardData, this.initData)
        Object.assign(this.dashboardData, this.indexMaxValues_terr_dec)
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    findKey(text) {
      return Object.entries(checklistQuestions).find(e => e[1] === text)?.[0]
    },

    checkParameter(text) {
      let key = this.findKey(text);
      console.log()
      return this.iccDataChecklists.find(e => e.czn_id === this.userDepartment.id)?.[key];
    },

    getDynamicParameter(parameters, relevance) {
      for (let parameter of parameters) {
        if (parameter.parameterName.includes(relevance)) {
          return parameter;
        }
      }
    },

    countParameterDynamic(parameters) {
      let currentValue = this.parametersTextPartMap.get(this.getDynamicParameter(parameters, 'current').parameterName);
      let previousValue = this.parametersTextPartMap.get(this.getDynamicParameter(parameters, 'previous').parameterName);

      if (currentValue != null && currentValue !== "" && previousValue != null && previousValue !== "") {
        let result = (currentValue - previousValue) / previousValue * 100;

        if (Number.isInteger(result)) {
          return result + "%";
        } else {
          return result.toFixed(2) + "%";
        }

      } else {
        return '—';
      }
    },

    getEventsParameter(parameters, relevance) {
      for (let parameter of parameters) {
        if (parameter.parameterName.includes(relevance)) {
          return this.parametersTextPartMap.get(parameter.parameterName) ? this.parametersTextPartMap.get(parameter.parameterName) : '';
        }
      }
    },

    getIndexKey(index_group, index, param) {
      return 'indexGroup' + index_group + '_index' + index + '_param' + param
    },

    vne(rows, i, j, k){
      return rows[this.getIndexKey(i, j, k)]
    },

    countNonNullParamsList(group, index, params_list){
      const rows = this.iccDataChecklists.find(e=>e.czn_id === this.userDepartment.id)
      if(!rows || rows.length === 0) return 0
      let r = 0
      for(let i of params_list) {
        r = r + this.vne(rows, group, index, i)
      }
      return r
    },

    getLevelValue(){
      const lvs = this.getLevelValueConsts()
      if(lvs.l3 < lvs.level3Indices.length-3) return 0
      if(lvs.l2 !== lvs.level2Indices.length) return 3
      if(lvs.l1 !== lvs.level1Indices.length) return 2
      return 1
    },

    updateLevelValueConsts(){
      const level3Indices = [2, 3, 14, 15, 16, 25, 26, 27, 33, 40]
      const level2Indices = [4, 17, 21, 22, 23, 28, 29, 30, 34, 35, 36, 41]
      const level1Indices = [5, 6, 7, 8, 9, 10, 11, 12, 13, 18, 19, 20, 24, 31, 32, 37, 38, 39, 42]
      const l3 = this.countNonNullParamsList(4, 3, level3Indices)
      const l2 = this.countNonNullParamsList(4, 3, level2Indices)
      const l1 = this.countNonNullParamsList(4, 3, level1Indices)
      this.levelValuesCached = {l3, l2, l1, level3Indices, level2Indices, level1Indices}
    },

    getLevelValueConsts() {
      return this.levelValuesCached
    },

    async initialize(marker) {
      this.apiLoaded = false;
      await this.loadDashboardData();
      this.apiLoaded = true;
      this.show = false;
      this.expandedPanels = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      if (!this.regionView) {
        if (marker === 'previous') {
          await this.loadAnotherCheckListValues('previousFormData');
        } else if (marker === 'next') {
          await this.loadAnotherCheckListValues('nextFormData');
        } else {
          await this.loadCheckListValues();
        }
      }
      this.expandedPanels = [];
      this.show = true;
      this.$forceUpdate();
    },

    getAnswersCount(index){
      const ankets = index.slice(1,2) === 's' ? this.cznSoiskAnswers : this.cznRabAnswers
      const question = questionsDict[index]
      return ankets.reduce(
          (accumulator, currentValue) => {
            return currentValue[question] != null ? accumulator + 1 : accumulator
          }, 0
      )
    },

    getDiagramData(index) {
      const ankets = index.slice(1,2) === 's' ? this.cznSoiskAnswers : this.cznRabAnswers
      const question = questionsDict[index]
      return [
        ["Persons", "Количество", { role: 'annotation' }, { role: 'style' }],
        getRow(1, "#999999"),
        getRow(2, "#999999"),
        getRow(3, "#999999"),
        getRow(4, "#F2994A"),
        getRow(5, "#2F80ED"),
        getRow(6, "#2F80ED"),
        getRow(7, "#2F80ED"),
        getRow(8, "#27AE60"),
        getRow(9, "#27AE60"),
        getRow(10, "#27AE60"),
      ]

      function getRow(i, color){
        let r = ankets.reduce(
            (accumulator, currentValue) => {
              return currentValue[question] === (i-1) ? accumulator + 1 : accumulator
            }, 0
        )
        return [i.toString(), r, r.toString(), color]
      }
    },

    getDiagramData1() {
      return [
        ["Persons", "Индекс", { role: 'annotation' }, { role: 'style' }],
        ["Да",  40, '40', "#27AE60"],
        ["Нет", 10, '10', "#F2994A"],
        ["Не знаю", 20, '20', "#999999"],
      ]
    },

    getQuarter(currentDate) {
      if (currentDate == null) {
        currentDate = new Date();
      } else {
        currentDate = new Date(currentDate);
      }

      if (currentDate >= new Date(currentDate.getFullYear() + '-1-21 00:00:00')
          && currentDate <= new Date(currentDate.getFullYear() + '-4-15 23:59:59')) {
        return {value: 'I', year: currentDate.getFullYear()};
      } else if (currentDate >= new Date(currentDate.getFullYear() + '-4-16 00:00:00')
          && currentDate <= new Date(currentDate.getFullYear() + '-7-15 23:59:59')) {
        return {value: 'II', year: currentDate.getFullYear()};
      } else if (currentDate >= new Date(currentDate.getFullYear() + '-7-16 00:00:00')
          && currentDate <= new Date(currentDate.getFullYear() + '-10-15 23:59:59')) {
        return {value: 'III', year: currentDate.getFullYear()};
      } else {
        if (currentDate <= new Date(currentDate.getFullYear() + '-1-21 00:00:00')) {
          return {value: 'IV', year: currentDate.getFullYear() - 1};
        } else {
          return {value: 'IV', year: currentDate.getFullYear()};
        }
      }
    },

    checkQuarter() {
      return JSON.stringify(this.currentQuarter) === JSON.stringify(this.getQuarter());
    },

    async previousQuarter() {
      if (this.currentQuarter.value === 'IV') {
        this.currentQuarter.value = 'III';
      } else if (this.currentQuarter.value === 'III') {
        this.currentQuarter.value = 'II';
      } else if (this.currentQuarter.value === 'II') {
        this.currentQuarter.value = 'I';
      } else if (this.currentQuarter.value === 'I') {
        this.currentQuarter.value = 'IV';
        this.currentQuarter.year = this.currentQuarter.year - 1;
      }
      await this.initialize('previous');
      this.$forceUpdate();
    },

    async nextQuarter() {
      if (this.currentQuarter.value === 'I') {
        this.currentQuarter.value = 'II';
      } else if (this.currentQuarter.value === 'II') {
        this.currentQuarter.value = 'III';
      } else if (this.currentQuarter.value === 'III') {
        this.currentQuarter.value = 'IV';
      } else if (this.currentQuarter.value === 'IV') {
        this.currentQuarter.value = 'I';
        this.currentQuarter.year = this.currentQuarter.year + 1;
      }
      await this.initialize('next');
      this.$forceUpdate();
    },

    async getDynamicDiagram() {
      let beginOfPeriod = this.getQuarter(this.dynamicStartDate);
      let endOfPeriod = this.getQuarter(this.dynamicEndDate);

      let req;
      if (this.userRegion.netType === 'DECENTRALIZED') {
        req = await this.$getApi("/icc/index/getAfterAndBefore",
            {
              afterQuarter : beginOfPeriod.value,
              afterYear : beginOfPeriod.year,
              beforeQuarter  : endOfPeriod.value,
              beforeYear  : endOfPeriod.year,
              cznId: this.userDepartment.id,
              clientCentricityConfigId: this.configId
            });
      } else if (this.userRegion.netType === 'CENTRALIZED') {
        if (this.userDepartment.type === 'territorial_czn') {
          req = await this.$getApi("/icc/index/territorial/getAfterAndBefore",
              {
                afterQuarter : beginOfPeriod.value,
                afterYear : beginOfPeriod.year,
                beforeQuarter  : endOfPeriod.value,
                beforeYear  : endOfPeriod.year,
                cznId: this.userDepartment.id,
                clientCentricityConfigId: this.configId
              });
        } else if (this.userDepartment.type === 'manager_czn') {
          req = await this.$getApi("/icc/index/manager/getAfterAndBefore",
              {
                afterQuarter : beginOfPeriod.value,
                afterYear : beginOfPeriod.year,
                beforeQuarter  : endOfPeriod.value,
                beforeYear  : endOfPeriod.year,
                cznId: this.userDepartment.id,
                clientCentricityConfigId: this.configId
              });
        }
      }

      let result = [["Квартал", "Индекс", { role: 'annotation' }]];
      if (this.dynamicIndexGroup != null && this.dynamicIndex != null && this.dynamicType != null) {
        for (let record of req.payload) {
          let quarter = record.quarter + ' квартал ' + record.year;
          let value = record[this.dynamicIndexGroup.value][this.dynamicIndex][this.dynamicType];
          let stringValue = `${record[this.dynamicIndexGroup.value][this.dynamicIndex][this.dynamicType]}`;
          let element = [quarter, value, stringValue]
          result.push(element);
        }
      } else if (this.dynamicIndexGroup != null && this.dynamicType != null) {
        for (let record of req.payload) {
          let quarter = record.quarter + ' квартал ' + record.year;
          let value = record[this.dynamicIndexGroup.value][this.dynamicType];
          let stringValue = `${record[this.dynamicIndexGroup.value][this.dynamicType]}`;
          let element = [quarter, value, stringValue]
          result.push(element);
        }
      } else if (this.dynamicType!= null) {
        for (let record of req.payload) {
          let quarter = record.quarter + ' квартал ' + record.year;
          let value = record[this.dynamicType];
          let stringValue = `${record[this.dynamicType]}`;
          let element = [quarter, value, stringValue]
          result.push(element);
        }
      }
      this.chartData = result;
    },

    getGenderChartData() {
      const cznGenderAnswers = genderAnswers.filter(e=>e[3] === this.userDepartment.id.toString())
      console.log(cznGenderAnswers.length)
      console.log(typeof this.userDepartment.id)

      const M = cznGenderAnswers.reduce(
          (accumulator, currentValue) => {
            return currentValue[0] === 0 ? accumulator + 1 : accumulator
          }, 0
      )
      const F = cznGenderAnswers.reduce(
          (accumulator, currentValue) => {
            return currentValue[0] === 1 ? accumulator + 1 : accumulator
          }, 0
      )
      return [
        ["Пол", "Количество ", { role: 'annotation' }],
        ["Мужской", M, M],
        ["Женский", F, F],
      ]
    },

    getAgechartData() {
      const cznGenderAnswers = genderAnswers.filter(e=>e[3] === this.userDepartment.id.toString())

      return [
        ["Возраст", "Количество "],
        ["Младше 18 лет", getAgeCat(0)],
        ["18-19 лет", getAgeCat(1)],
        ["20-29 лет", getAgeCat(2)],
        ["30-39 лет", getAgeCat(3)],
        ["40-49 лет", getAgeCat(4)],
        ["50-59 лет", getAgeCat(5)],
        ["60-69 лет", getAgeCat(6)],
        ["70 лет и старше", getAgeCat(7)],
      ]

      function getAgeCat(cat){
        return cznGenderAnswers.reduce(
            (accumulator, currentValue) => {
              return currentValue[1] === cat ? accumulator + 1 : accumulator
            }, 0)
        }
    },

    getCategoriesChartData() {
      const cznGenderAnswers = genderAnswers.filter(e=>e[3] === this.userDepartment.id.toString())

      return [
        ["Категория", "Количество "],
        ["Граждане предпенсионного возраста", getInvalidCat(0)],
        ["Пенсионеры", getInvalidCat(1)],
        ["Инвалиды", getInvalidCat(2)],
        ["Дети-сироты", getInvalidCat(3)],
        ["Уволенные по сокращению штатов или ликвидации организации", getInvalidCat(4)],
        ["Выпускники школ", getInvalidCat(5)],
        ["Выпускники средне-профессиональных и высших учебных заведений", getInvalidCat(6)],
        ["Женщины с детьми от 0 до 6 лет", getInvalidCat(7)],
        ["Не работающие более года", getInvalidCat(8)],
        ["Не относятся ни к одной из перечисленных категорий граждан", getInvalidCat(9)],
      ]

      function getInvalidCat(cat){
        return cznGenderAnswers.reduce(
            (accumulator, currentValue) => {
              return currentValue[2] === cat ? accumulator + 1 : accumulator
            }, 0)
      }
    },

    initializeMockData(){
      this.show = false
      if (this.userDepartment === 'По всем ЦЗН региона'){
        this.dashboardData = this.regionIccData.find(e=>{
          return e.region_code === this.userRegion.code
        }) || {}
        this.cznSoiskAnswers = []
        this.cznRabAnswers = []
        this.viewMode = 'REGION'
      } else {
        this.dashboardData = this.cznIccData.find(e=>{
          return e.cznId === this.userDepartment.id
        }) || {}
        this.cznSoiskAnswers = this.soiskAnswers.filter(e=>{
          return e.cznId === this.userDepartment.id
        })
        this.cznRabAnswers = this.rabAnswers.filter(e=>{
          return e.cznId === this.userDepartment.id
        })

        if(this.userRegion.netType === 'DECENTRALIZED') {
          this.viewMode = 'CZN_DECENTRALIZED'
        } else {
          if(this.userDepartment.type === 'manager_czn') {
            this.viewMode = 'CZN_MANAGER'
          } else {
            this.viewMode = 'CZN_CENTRALIZED_TER'
          }
        }

        this.updateLevelValueConsts();

      }

      this.dashboardData = roundNumericFields(this.dashboardData)

      if(this.viewMode === 'REGION' || this.viewMode === 'CZN_DECENTRALIZED') {
        Object.assign(this.dashboardData, this.indexMaxValues_terr_dec)
      } else if(this.viewMode === 'CZN_MANAGER') {
        Object.assign(this.dashboardData, this.indexMaxValues_upr)
      } else {
        Object.assign(this.dashboardData, this.indexMaxValues_terr_cen)
      }


      this.show = true

    },

    floor2(val){
      return Math.floor(val*100)/100
    },

    computeBaseDynamics(){
      const cznRow = this.iccDataChecklists.find(e=>e.czn_id === this.userDepartment.id);
      if(!cznRow) return 0;
      const curr = cznRow['indexGroup2_index1_param1_current']
      const prev = cznRow['indexGroup2_index1_param1_previous']
      if(prev > 0) {
        return isNaN(curr/prev) ? 0 : curr/prev;
      }
      return 0;
    },
  },

  async beforeMount() {
    this.currentQuarter = this.getQuarter();
    //await this.getActiveConfig();
    this.apiLoaded = true;
    //await this.initialize();
    this.initializeMockData();
    this.expandedPanels = [];

  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;
    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }
    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}



.clientindex {
  div.blue {
    background: #E1F0FA !important;
    color: #2F80ED !important;
  }
  div.green {
    background: #E2F5EA !important;
    color: #27AE60 !important;
  }
  div.yellow {
    background: #FCF4DB !important;
    color: #F2994A !important;
  }
  dv.darkblue {
    background: #E5EBF5 !important;
    color: #0033A0 !important;
  }
  .badge {
    height: 30px;
    margin-left: 4px;
    margin-right: 0;
    font-size: 13px;
    font-weight: 700;
  }


  .v-slide-group__content.v-tabs-bar__content {
    justify-content: space-around !important;
  }
  .v-window-item {
    padding-top: 50px;
  }
  .v-expansion-panel .level2 {
    padding-top: 5px;
    .v-expansion-panel-content {
      padding-left: 44px;
    }
  }

  .v-expansion-panel-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    margin: 10px 0;
  }
  .list .list-item-wrap .list-item-name {
    max-width: none;
    .toggleSwitch {
      align-self: center;
    }
  }
  .list .list-item-wrap .list-item {
    justify-content: flex-start;
    .v-progress-circular {
      margin-left: 0;
      .v-progress-circular__info {
        font-size: 16px;
        font-weight: 600;
        display: block;
        .text-caption {
          font-size: 10px !important;
          vertical-align: baseline;
          display: inline;
        }
      }
    }
  }
  .list-item-wrap .list-item-wrap {
    border: none;
    padding-bottom: 5px;
  }
}

.graph-wrap {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 30px 30px 0 30px;
  h3 {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    text-align: center;
    margin-bottom: 10px;
  }
  .graph-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 10px;
    div:first-child {
      font-size: 36px;
      line-height: 30px;
      span {
        font-size: 12px;
        vertical-align: text-top;
      }
    }
    div:last-child {
      font-weight: 600;
      font-size: 16px;
      color: #1C1C1F;
    }
    &.text-blue {
      color: #2F80ED;
      div:last-child span {
        color: #2F80ED;
      }
    }
    &.text-orange {
      color: #F2994A;
      div:last-child span {
        color: #F2994A;
      }
    }

  }
  &.checklist-wrap {
    padding: 20px;
    display: flex;
    gap: 30px;
    background: #F3F3F3;
    border-radius: 20px;
    min-height: 130px;
    > div:first-child {
      width: 140px;
      div {
        color: #2F80ED;
        font-size: 36px;
        line-height: 30px;
        font-weight: 600;
        span {
          vertical-align: text-top;
          font-size: 12px;
          color: #2F80ED;
        }
      }
    }
    .checklist > div {
      display: flex;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      gap: 10px;
      span {
        width: 14px;
      }
      svg {
        margin-top: 4px;
      }
      &.checked {
        color: #0033A0;
      }
    }
    .v-progress-circular__info {
      font-size: 16px;
      display: block;
      font-weight: 600;
      .text-caption {
        font-size: 7px !important;
        vertical-align: baseline;
      }
    }
  }
}

.line-wrap {
  border: 1px solid #E8E8E9;
  border-radius: 4px;
}

</style>
