<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Выгрузка дашборда</span>
      <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>

    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col сols="12" sm="12">
              <v-select label="Группа показателей"
                        :items="groups"
                        item-text="title"
                        return-object
                        v-model="indexGroup"
                        @change="errorText = null"></v-select>
            </v-col>
            <v-col сols="12" sm="12">
              <v-select v-if="indexGroup != null"
                        label="Показатель"
                        :items="indexGroup.indices.filter((it) => it.haveDashboard)"
                        item-text="title"
                        item-value="value"
                        v-model="index"
                        @change="errorText = null"></v-select>
              <v-select v-else
                        label="Показатель"
                        disabled></v-select>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="btn btn-outline"
             text
             @click="$emit('close')">
        Закрыть
      </v-btn>
      <v-btn class="btn btn-primary"
             @click="submit()">
        Скачать
      </v-btn>
      <div
          v-if="errorText != null"
          class="error"
          style="position: fixed; bottom: 0"
      >
        Ошибка: {{ errorText }}
      </div>
    </v-card-actions>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-card>
</template>

<script>
import LoaderDialog from "@/components/elements/LoaderDialog";
export default {
  name: "DashboardExportDialog",
  components: {LoaderDialog},
  props:['indexGroups', 'refs'],
  data: () => ({
    indexGroup: null,
    index: null,
    errorText: null,
    groups: null,
    showLoaderDialog: false,
  }),

  methods: {
    submit() {
      this.showLoaderDialog = true;
      if (this.indexGroup !== null && this.index !== null) {
        this.generateReport('html2Pdf.' + this.indexGroup.value + (this.index === '' ? '' : '.' + this.index));

      } else if (this.indexGroup === null && this.index === null) {
        this.errorText = 'Необходимо заполнить поля "Группа показателей и "Показатель"';
      } else if (this.indexGroup === null) {
        this.errorText = 'Необходимо заполнить поле "Группа показателей"';
      } else if (this.index === null) {
        this.errorText = 'Необходимо заполнить поле "Показатель"';
      }
      this.showLoaderDialog = false;
      this.$emit("close");
    },

    generateReport(element) {
      this.refs[element].generatePdf();
    }
  },

  beforeMount() {
    this.groups = JSON.parse(JSON.stringify(this.indexGroups));
    this.groups.push({
      title: 'Социально-демографическая характеристика опрошенных', value: 'demographic',
      indices: [
        {
          title: 'Социально-демографическая характеристика опрошенных',
          value: '',
          haveDashboard: true
        },
      ]
    });
    this.groups.push({
      title: 'Динамика изменения', value: 'dynamic',
      indices: [
        {
          title: 'Динамика изменения',
          value: '',
          haveDashboard: true
        },
      ]
    });
  }
}
</script>

<style scoped>

</style>