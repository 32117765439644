<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <div  class="wrap clientindex">
          <h1>Индекс клиентоцентричности

            <span v-if="$user.role === 'ROLE_CZN_TERRITORIAL'" style="font-size: 17px; color: #1C1C1F; font-weight: 600;">{{ $user.department.name }}</span>

            <span v-else-if="$user.role === 'ROLE_CZN_MANAGER' || $user.role === 'ROLE_REGIONAL_AGENCY'">
              <span class="left-element" style="padding-top: 18px">{{ $user.region.name }}</span>
              <v-autocomplete label="ЦЗН"
                              v-model="userDepartment"
                              :items="departments"
                              item-text="name"
                              return-object
                              class="right-element"
                              @input="$forceUpdate()"
              ></v-autocomplete>
              <p style="clear: both; margin: 0"></p>
            </span>

            <span v-else>
<!--              <v-row>-->
<!--              <v-col>-->
              <v-autocomplete label="Регион"
                              v-model="userRegion"
                              :items="$cznDepartmentsByRegionList"
                              item-text="name"
                              return-object
                              class="left-element"
                              @change="setDefaultDepartment()"
              ></v-autocomplete>
<!--              </v-col>-->
<!--              <v-col>-->
              <v-autocomplete label="ЦЗН"
                              v-model="userDepartment"
                              :items="departments"
                              item-text="name"
                              return-object
                              class="right-element"
                              @input="$forceUpdate()"
              ></v-autocomplete>
<!--              </v-col>-->
<!--              </v-row>-->
              <p style="clear: both; margin: 0"></p>
            </span>

          </h1>

          <v-tabs>
            <v-tab @click="mainKey++; isMainTab = true; changeDepartment(true)">Индекс Клиентоцентричности</v-tab>
            <v-tab @click="anketKey++; isMainTab = false; changeDepartment(false)">Субъективные данные</v-tab>
            <v-tab @click="checkListKey++; isMainTab = false; changeDepartment(false)">Объективные данные</v-tab>

            <v-tab-item>
              <ClientCentricityMain
                  :user-region="userRegion"
                  :user-department="userDepartment"
                  :key="mainKey"></ClientCentricityMain>
            </v-tab-item>

            <v-tab-item>
              <ClientCentricityAnket
                  :user-region="userRegion"
                  :user-department="userDepartment"
                  :key="anketKey"></ClientCentricityAnket>
            </v-tab-item>

            <v-tab-item>
              <ClientCentricityCheckList
                  :user-region="userRegion"
                  :user-department="userDepartment"
                  :key="checkListKey"></ClientCentricityCheckList>
            </v-tab-item>

          </v-tabs>
      </div>
    </transition>
  </div>
</template>

<script>

import ClientCentricityAnket from "@/components/ClientCentricityAnket";
import ClientCentricityCheckList from "@/components/ClientCentricityCheckList";
import ClientCentricityMain from "@/components/ClientCentricityMain";


export default {
  name: 'ClientIndexComponent',
  components: {
    ClientCentricityMain,
    ClientCentricityCheckList,
    ClientCentricityAnket,
  },

  data: () => ({
    userRegion: null,
    userDepartment: null,
    departments: null,
    mainKey: 0,
    checkListKey: 0,
    anketKey: 0,
    isMainTab: true,
  }),

  methods: {
    setDefaultDepartment() {
      this.departments = JSON.parse(JSON.stringify(this.userRegion.departments));
      if (this.isMainTab) {
        this.departments.unshift('По всем ЦЗН региона')
      }
      if (this.$user.role === 'ROLE_CZN_MANAGER') {
        this.userDepartment = this.$user.department
      } else {
        this.userDepartment = this.departments[0];
      }
      this.$forceUpdate();
    },

    changeDepartment(isMainTab) {
      if (isMainTab) {
        if (!this.departments.includes('По всем ЦЗН региона')) {
          this.departments.unshift('По всем ЦЗН региона');
        }
      } else {
        this.departments = this.departments.filter(it => it !== 'По всем ЦЗН региона');

        if (this.userDepartment === 'По всем ЦЗН региона') {
          this.userDepartment = this.departments[0];
        }
      }
      this.$forceUpdate();
    }
  },

  async beforeMount() {
    if (this.$user.role === 'ROLE_CZN_TERRITORIAL' || this.$user.role === 'ROLE_CZN_MANAGER') {
      this.userRegion = this.$cznDepartmentsByRegionList.find(it => it.name === this.$user.region.name);
      if (this.userRegion.departments) {
        this.departments = JSON.parse(JSON.stringify(this.userRegion.departments));
        this.departments.unshift('По всем ЦЗН региона')
      }
      this.userDepartment = this.$user.department;
    }
    else if (this.$user.role === 'ROLE_REGIONAL_AGENCY') {
      this.userRegion = this.$cznDepartmentsByRegionList.find(it => it.name === this.$user.region.name);

      if (this.userRegion.departments) {
        this.departments = JSON.parse(JSON.stringify(this.userRegion.departments));
        this.departments.unshift('По всем ЦЗН региона')
      }

      this.userDepartment = this.departments[0];

    }
    else { //для админов и ВНИИ Труда
      this.userRegion = this.$cznDepartmentsByRegionList[0];
      this.departments = JSON.parse(JSON.stringify(this.userRegion.departments));
      this.departments.unshift('По всем ЦЗН региона')
      this.userDepartment = this.departments[0];
    }
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";

.top-progress {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;
    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }
    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}



.clientindex {
  div.blue {
  background: #E1F0FA !important;
  color: #2F80ED !important;
}
div.green {
  background: #E2F5EA !important;
  color: #27AE60 !important;
}
div.yellow {
  background: #FCF4DB !important;
  color: #F2994A !important;
}
dv.darkblue {
  background: #E5EBF5 !important;
  color: #0033A0 !important;
}
.badge {
  height: 30px;
  margin-left: 4px;
  margin-right: 0;
  font-size: 13px;
  font-weight: 700;
}


  .v-slide-group__content.v-tabs-bar__content {
    justify-content: space-around !important;
  }
  .v-window-item {
    padding-top: 50px;
  }
  .v-expansion-panel .level2 {
    padding-top: 5px;
    .v-expansion-panel-content {
      padding-left: 44px;
    }
  }

  .v-expansion-panel-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    margin: 10px 0;
  }
  .list .list-item-wrap .list-item-name {
    max-width: none;
    .toggleSwitch {
      align-self: center;
    }
  }
  .list .list-item-wrap .list-item {
    justify-content: flex-start;
    .v-progress-circular {
      margin-left: 0;
            .v-progress-circular__info {
    font-size: 16px;
    font-weight: 600;
    display: block;
    .text-caption {
      font-size: 10px !important;
      vertical-align: baseline;
      display: inline;
    }
  }
    }
  }
  .list-item-wrap .list-item-wrap {
    border: none;
    padding-bottom: 5px;
  }
}

.graph-wrap {
 background: #F3F3F3;
border-radius: 20px;
  padding: 30px 30px 0 30px;
  h3 {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    text-align: center;
    margin-bottom: 10px;
  }
  .graph-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 10px;
    div:first-child {
      font-size: 36px;
      line-height: 30px;
      span {
        font-size: 12px;
        vertical-align: text-top;
      }
    }
    div:last-child {
      font-weight: 600;
      font-size: 16px;
      color: #1C1C1F;
    }
    &.text-blue {
      color: #2F80ED;
      div:last-child span {
        color: #2F80ED;
      }
    }
    &.text-orange {
      color: #F2994A;
      div:last-child span {
        color: #F2994A;
      }
    }

  }
  &.checklist-wrap {
    padding: 20px;
    display: flex;
    gap: 30px;
    background: #F3F3F3;
    border-radius: 20px;
    min-height: 130px;
    > div:first-child {
      width: 140px;
      div {
        color: #2F80ED;
        font-size: 36px;
        line-height: 30px;
        font-weight: 600;
        span {
          vertical-align: text-top;
          font-size: 12px;
          color: #2F80ED;
        }
      }
    }
    .checklist > div {
      display: flex;
      margin-bottom: 10px;
      font-weight: 500;
font-size: 14px;
line-height: 160%;
       gap: 10px;
      span {
        width: 14px;
      }
      svg {
        margin-top: 4px;
      }
      &.checked {
        color: #0033A0;
      }
    }
          .v-progress-circular__info {
    font-size: 16px;
    display: block;
    font-weight: 600;
    .text-caption {
      font-size: 7px !important;
      vertical-align: baseline;
    }
  }
  }
}

.line-wrap {
  border: 1px solid #E8E8E9;
border-radius: 4px;
}

.left-element {
  float: left;
  width: calc(40% - 10px);
  margin-right: 20px;
  //span {
    font-size: 17px;
    color: #1C1C1F;
    font-weight: 600;
  //}
}

.right-element {
  float: right;
  width: calc(60% - 10px);
}

</style>
